import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import { v4 as uuidv4 } from 'uuid'
import { Dropdown } from './Library'

export class TillOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class BusinessOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class InventoryItemOption {
	constructor(key, value, text, subDescription, unitCost) {
		this.label = text;
		this.key = key;
		this.value = value;
		this.subDescription = subDescription;
		this.unitCost = unitCost;
    }
}



export class Receipt extends Component {
	constructor(props) {
		super(props)
		this.state = {
			receiptId: null,
			businessId: null,
			createdDateTime: null,
			businessReceiptNumber: '',
			tillId: null,
			tills: [],
			saleTime: null,
			tipAmount: null,
			taxAmount: null,
			cashTendered: null,
			businesss: [],
			inventoryItems: [],
			lineItems: [
				{
					description: '',
					subDescription: '',
					numberOfItems: 0,
					unitCost: 0.00,
					inventoryItemId: null
				},
			],
			businessLoading: true,
			tillLoading: true,
			inventoryLoading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getTillData();
		this.getBusinessData();

		this.setDateControlToCurrentDateTime('saleTime');
	}

	setDateControlToCurrentDateTime(fieldIdentifier) {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
		var localISOTimeWithoutSeconds = localISOTime.slice(0, 16);

		var datePicker = document.querySelector('input[name=' + fieldIdentifier + ']');
		datePicker.value = localISOTimeWithoutSeconds;
		this.setState({
			[fieldIdentifier]: localISOTimeWithoutSeconds
		})
	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleBusinessDropDownChange = (e) => {
		const businessId = e.target.value;
		this.setState({
			"businessId": businessId
		})
		this.getInventoryItemData(businessId);
		this.getTillData(businessId);
	}

	handleTillDropDownChange = (e) => {
		this.setState({
			"tillId": e.target.value
		})
	}

	handleInventoryItemDropDownChange = (elementIndex) => (event) => {
		const inventoryItemId = event.target.value;
		const inventoryItem = this.state.inventoryItems.find((item) => { return item.key == inventoryItemId });
		let lineItems = this.state.lineItems.map((item, i) => {
			if (elementIndex !== i) return item
			return { ...item, "inventoryItemId": event.target.type === 'number' ? Number(event.target.value) : event.target.value, "description": inventoryItem.label, "unitCost": inventoryItem.unitCost, "subDescription": inventoryItem.subDescription }
		})

		this.setState({ lineItems })

    }

	handleSubmit = (event) => {

		this.state.receiptId = uuidv4();

		var receiptLabelNode = document.getElementById("receiptLabel");
		var qrReceiptNode = document.getElementById("qrReceiptCode");
		var tillLabelNode = document.getElementById("tillLabel");
		var qrTillNode = document.getElementById("qrTillCode");
		var spaceMessageNode = document.getElementById("whiteSpaceMessage");

		var qrReceiptValue = process.env.REACT_APP_API_ENDPOINT + "receipt/" + this.state.receiptId;
		var qrTillValue = process.env.REACT_APP_API_ENDPOINT + "receipt/till/" + this.state.tillId;

		ReactDOM.render(<label>Receipt</label>, receiptLabelNode);

		ReactDOM.render(<QRCode value={qrReceiptValue} />,
			qrReceiptNode
		);

		ReactDOM.render(<label>Till</label>, tillLabelNode);

		ReactDOM.render(
			<QRCode value={qrTillValue} />,
			qrTillNode
		);

		ReactDOM.render(<label>Space at bottom for easier QR Scanning</label>, spaceMessageNode)

		var receiptOrder = {
			businessId: this.state.businessId,
			lineItems: this.state.lineItems
        }

		var receipt = {
			receiptPaymentId: this.state.receiptId,
			partnerReceiptId: this.state.businessReceiptNumber,
			tillId: this.state.tillId,
			saleTime: this.state.saleTime,
			amount: this.calcLineItemsTotal(),
			tipAmount: this.state.tipAmount,
			taxAmount: this.state.taxAmount,
			cashTendered: this.state.cashTendered,
			receiptOrder: receiptOrder
		}
		console.log(JSON.stringify(receipt));
		fetch(process.env.REACT_APP_API_ENDPOINT + 'receipt', {
			method: 'POST',
			body: JSON.stringify(receipt),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(response => { this.successCallBack(response) }, this.failureCallBack);

		event.preventDefault();
	}

	successCallBack(result) {
		if (result.status == 200) {
			var responseMessageNode = document.getElementById('responseMessage');
			ReactDOM.render(<label>Receipt uploaded successfully</label>, responseMessageNode);
		} else {
			this.failureCallBack();
        }
	}

	failureCallBack() {
		var receiptLabelNode = document.getElementById("receiptLabel");
		var qrReceiptNode = document.getElementById("qrReceiptCode");
		var tillLabelNode = document.getElementById("tillLabel");
		var qrTillNode = document.getElementById("qrTillCode");
		var responseMessageNode = document.getElementById('responseMessage');

		ReactDOM.render('', receiptLabelNode);
		ReactDOM.render('', qrReceiptNode);
		ReactDOM.render('', tillLabelNode);
		ReactDOM.render('', qrTillNode);
		ReactDOM.render(<label>Receipt failed to upload</label>, responseMessageNode);
    }

	async getBusinessData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business');
		const data = await response.json();
		this.setState({ businesss: data.map((business) => new BusinessOption(business.businessId, business.businessId, business.businessName)), businessLoading: false });
	}

	DisplayBusinessDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.businesss}
			onChange={this.handleBusinessDropDownChange}
			value={value}
		/>
		);
	}

	async getTillData(businessId) {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business/' + businessId + '/tills');
		const data = await response.json();
		this.setState({ tills: data.map((till) => new TillOption(till.tillId, till.tillId, till.model + " - " + till.serial)), tillLoading: false });
	}

	DisplayTillDropdown() {
		const { value } = this.state;
		if (this.state.businessId == null) {
			return <p><em>Please select business</em></p>;
		}

		if (this.state.tillLoading) {
			return <p><em>Loading...</em></p>
		}


		return (<Dropdown
			options={this.state.tills}
			onChange={this.handleTillDropDownChange}
			value={value}
		/>
		);
	}

	async getInventoryItemData(businessId) {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business/' + businessId + '/inventoryItems');
		const data = await response.json();
		this.setState({ inventoryItems: data.map((inventoryItem) => new InventoryItemOption(inventoryItem.inventoryItemId, inventoryItem.inventoryItemId, inventoryItem.name, inventoryItem.alternateName, inventoryItem.price)), inventoryLoading: false });
	}

	DisplayInventoryDropdown(lineItemOrdinal) {
		const { value } = this.state.lineItems;
		if (this.state.businessId == null) {
			return <p><em>Please select business</em></p>;
		}

		if (this.state.inventoryLoading) {
			return <p><em>Loading...</em></p>
        }

		return (<Dropdown
			options={this.state.inventoryItems}
			onChange={this.handleInventoryItemDropDownChange(lineItemOrdinal)}
			value={value}
			name="inventoryItemId"
		/>
		);
    }

	handleLineItemChange = (elementIndex) => (event) => {

		let lineItems = this.state.lineItems.map((item, i) => {
			if (elementIndex !== i) return item
			return { ...item, [event.target.name]: event.target.type === 'number' ? Number(event.target.value) : event.target.value }
		})

		this.setState({ lineItems })

	}

	handleFocusSelect = (event) => {
		event.target.select()
	}

	handleAddLineItem = (event) => {

		this.setState({
			lineItems: this.state.lineItems.concat(
				[{ description: '', subDescription: '', numberOfItems: 0, unitCost: 0.00 }]
			)
		})

	}

	handleRemoveLineItem = (elementIndex) => (event) => {
		this.setState({
			lineItems: this.state.lineItems.filter((item, i) => {
				return elementIndex !== i
			})
		})
	}

	formatCurrency = (amount) => {
		return (new Intl.NumberFormat(this.locale, {
			currency: this.currency,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(amount))
	}

	calcTaxAmount = (c) => {
		return c * (this.state.taxRate / 100)
	}

	calcLineItemsTotal = () => {
		return this.state.lineItems.reduce((prev, cur) => (prev + (cur.numberOfItems * cur.unitCost)), 0)
	}

	calcTaxTotal = () => {
		return this.calcLineItemsTotal() * (this.state.taxRate / 100)
	}

	calcGrandTotal = () => {
		return this.calcLineItemsTotal() + this.calcTaxTotal()
	}

	GenerateReceiptIDAndCreateQRCode = () => {

    }

	render() {
		let tillDropdown = this.DisplayTillDropdown();
		let businessDropdown = this.state.businessLoading ? <p><em>Loading</em></p> : this.DisplayBusinessDropdown();

		return (
			<form>
				<label>
					Business
					{businessDropdown}
				</label>

				<label>
					BusinessReceiptNumber:
					<input type="text" name="businessReceiptNumber" value={this.state.businessReceiptNumber} onChange={this.handleInputChange} />
				</label>

				<label>
					Till
					{tillDropdown}
				</label>

				<label>
					SaleTime:
					<input type="datetime-local" name="saleTime" value={this.state.saleTime} onChange={this.handleInputChange} />
				</label>

				<label>
					TipAmount:
					<input type="number" name="tipAmount" value={this.state.tipAmount} onChange={this.handleInputChange} />
				</label>

				<label>
					TaxAmount:
					<input type="number" name="taxAmount" value={this.state.taxAmount} onChange={this.handleInputChange} />
				</label>

				<label>
					CashTendered:
					<input type="number" name="cashTendered" value={this.state.cashTendered} onChange={this.handleInputChange} />
				</label>

				<button type="button" onClick={this.handleAddLineItem}>Add line item</button>

				{this.state.lineItems.map((item, i) => (
					<div key={i}>
						<div>{i + 1}</div>
						<div>{this.DisplayInventoryDropdown(i)}</div>
						<div><input name="description" type="text" value={item.description} onChange={this.handleLineItemChange(i)} /></div>
						<div><input name="subDescription" type="text" value={item.subDescription} onChange={this.handleLineItemChange(i)} /></div>
						<div><input name="numberOfItems" type="number" step="1" value={item.numberOfItems} onChange={this.handleLineItemChange(i)} onFocus={this.handleFocusSelect} /></div>
						<div><input name="unitCost" type="number" step="0.01" min="0.00" max="9999999.99" value={item.unitCost} onChange={this.handleLineItemChange(i)} onFocus={this.handleFocusSelect} /></div>
						<div>{this.formatCurrency(item.numberOfItems * item.unitCost)}</div>
						<div>
							<button type="button"
								onClick={this.handleRemoveLineItem(i)}
							>Delete</button>
						</div>
					</div>
				))}
				<div>Total: {this.formatCurrency(this.calcLineItemsTotal()) }</div>
				<input type="submit" onClick={this.handleSubmit} value="Save" />
				<table><tr><td><div id="receiptLabel" /></td><td><div id="qrReceiptCode" /></td><td><div id="tillLabel" /></td><td><div id="qrTillCode" /></td></tr></table>
				<div id="whiteSpaceMessage" />
				<div id="responseMessage" />
			</form>
		);
	}
}
