import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import Label from '../../node_modules/reactstrap/es/Label'

export class EasyTest extends Component { 
    generateReceipt = (event) => {
        fetch(process.env.REACT_APP_API_ENDPOINT + 'receipt/receiptforbusinesswithproducts', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json(), this.failureCallBack).then(data => {this.displayData(data) });
    }

    generateLoyaltyCode = () => {
        fetch(process.env.REACT_APP_API_ENDPOINT + 'loyaltyoffer/randomexample', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json(), this.failureCallBack).then(data => { this.displayLoyaltyCode(data) });
    }

    successCallBack(result) {
        if (result.status != 200) {
            this.failureCallBack();
        }
    }


    displayData(data) {
        var qrNode = document.getElementById('qrCode');
        var labelNode = document.getElementById('receiptLabelNode');
        console.log(data);
        var receiptPaymentId = data.receiptPaymentId;

        var qrReceiptValue = process.env.REACT_APP_API_ENDPOINT + "receipt/" + receiptPaymentId;
        ReactDOM.render(<label>{qrReceiptValue}</label>, labelNode);
        ReactDOM.render(<QRCode value={qrReceiptValue} />, qrNode);
    }

    displayLoyaltyCode(data) {
        var qrNode = document.getElementById('qrCode');
        var labelNode = document.getElementById('receiptLabelNode');

        var loyaltyOfferId = data.loyaltyOfferId;

        var qrLoyaltyCodeValue = process.env.REACT_APP_API_ENDPOINT + "loyaltyoffer/" + loyaltyOfferId;
        ReactDOM.render(<label>{qrLoyaltyCodeValue}</label>, labelNode);
        ReactDOM.render(<QRCode value={qrLoyaltyCodeValue} />, qrNode);
    }

    failureCallBack() {
        var qrNode = document.getElementById('qrCode');
        ReactDOM.render(<Label>Poo</Label>, qrNode);
    }

render() {
    return (
        <form>
            <button type="button" onClick={this.generateReceipt}>Generate Receipt</button>
            <button type="button" onClick={this.generateLoyaltyCode }>Generate Loyalty Code</button>
            <table><tr><td><div id="receiptLabelNode" /></td><td><div id="qrCode" /></td></tr></table>
        </form>
        )
}
}