import React, { Component } from 'react'




export class Country extends Component {
	constructor(props) {
		super(props)
		this.state = {
			countryName: '',

			loading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {

	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e, data) => {
		const name = data.name;
		this.setState({
			[name]: data.value
		})
	}

	handleSubmit = (event) => {

		var country = {
			countryName: this.state.countryName
		}
		fetch(process.env.REACT_APP_API_ENDPOINT + 'country', {
			method: 'POST',
			body: JSON.stringify(country),
			headers: {
				'Content-Type': 'application/json'
			}
			}).then(function(response) {
			console.log(response)
			return response.json();
		});
		event.preventDefault();
	}



	render() {

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					CountryName:
					<input type="text" name="countryName" value={this.state.countryName} onChange={this.handleInputChange} />
				</label>


				<input type="submit" value="Save" />
			</form>
		);
	}
}
