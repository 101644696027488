import { Component } from 'react';
import { Home } from './components/Home';
import './custom.css'
import { Business } from './components/Business';
import { Country } from './components/Country';
import { Receipt } from './components/Receipt';
import { Voucher } from './components/Voucher';
import { Till } from './components/Till';
import { LoyaltyOffer } from './components/LoyaltyOffer'
import { CountryCode } from './components/CountryCode';
import { Message } from './components/Message';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NavMenu } from './components/NavMenu';
import { EasyTest } from './components/EasyTest';



export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <BrowserRouter>
                <NavMenu />
                <Switch>
                    <Route exact path='/'><Home /> </Route>
                    <Route path='/business'><Business /></Route>
                    <Route path='/country'><Country /></Route>
                    <Route path='/receipt'><Receipt /></Route>
                    <Route path='/voucher'><Voucher /></Route>
                    <Route path='/till'><Till /></Route>
                    <Route path='/countrycode'><CountryCode /></Route>
                    <Route path='/message'><Message /></Route>
                    <Route path='/loyaltyoffer'><LoyaltyOffer /></Route>
                    <Route path='/easyTest'><EasyTest/></Route>
                </Switch>
            </BrowserRouter>
        );
    }
}
