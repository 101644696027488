import React, { Component } from 'react'
import { Dropdown } from './Library'

export class LoyaltyOfferRulesOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class BusinessOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}



export class LoyaltyOffer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loyaltyOfferRuleId: null,
			businessId: '',
			signUpAlertText: '',
			spendAmountForPointOfferId: null,
			numberOfPoints: null,
			percentageOff: null,
			spendRequirement: null,
			loyaltyOfferRuless: [],
			businesss: [],
			loading: true,
			errorNumberOfPoints: '',
			errorPercentageOff: '',
			errorSpendRequirement: '',
			errorSignUpAlertText: '',
			errorLoyaltyOfferId: ''
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getLoyaltyOfferRulesData();
		this.getBusinessData();

	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleLoyaltyOfferRulesDropDownChange = (e) => {
		this.setState({
			"loyaltyOfferRuleId": e.target.value
		})
	}

	handleBusinessDropDownChange = (e) => {
		this.setState({
			"businessId": e.target.value
		})
	}

	handleSubmit = (event) => {
		const isValid = this.handleValidation();
		if (isValid) {
			var loyaltyOffer = {
				loyaltyOfferRuleId: this.state.loyaltyOfferRuleId,
				businessId: this.state.businessId,
				signUpAlertText: this.state.signUpAlertText
			}
			fetch(process.env.REACT_APP_API_ENDPOINT + 'loyaltyOffer', {
				method: 'POST',
				body: JSON.stringify(loyaltyOffer),
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(function (response) {
				console.log(response)
				return response.json();
			});
		}
		event.preventDefault();
	}

	async getLoyaltyOfferRulesData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'loyaltyOfferRule');
		const data = await response.json();
		this.setState({ loyaltyOfferRuless: data.map((loyaltyOfferRules) => new LoyaltyOfferRulesOption(loyaltyOfferRules.loyaltyOfferRuleId, loyaltyOfferRules.loyaltyOfferRuleId, loyaltyOfferRules.loyaltyOfferRuleName)), loading: false });

		for (var i = 0; i < data.length; i++) {
			var loyaltyOfferRule = data[i];

			if (loyaltyOfferRule.loyaltyOfferRuleName == 'Spend amount, get point. Earn points get % off') {
				this.setState({
					['spendAmountForPointOfferId']: loyaltyOfferRule.loyaltyOfferRuleId
				})
			}
		}
	}

	DisplayLoyaltyOfferRulesDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.loyaltyOfferRuless}
			onChange={this.handleLoyaltyOfferRulesDropDownChange}
			value={value}
		/>
		);
	} 

	async getBusinessData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business');
		const data = await response.json();
		this.setState({ businesss: data.map((business) => new BusinessOption(business.businessId, business.businessId, business.businessName)), loading: false });
}

	DisplayBusinessDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.businesss}
			onChange={this.handleBusinessDropDownChange}
			value={value}
		/>
		);
	}

	handleValidation() {

		let validated = true;

		if (this.state.signUpAlertText.length == 0) {
			this.setState({ errorSignUpAlertText: "You must have a sign up alert text" });
			validated = false;
		} else {
			this.setState({ errorSignUpAlertText: "" });
		}

		alert(this.state.loyaltyOfferRuleId);
		alert(this.state.loyaltyOfferRuleId == null);
		if (this.state.loyaltyOfferRuleId == null) {
			this.setState({ errorLoyaltyOfferId: "You must select a loyalty offer rule" });
			validated = false;
		} else {
			this.setState({ errorLoyaltyOfferId: "" });
        }

		if (this.state.loyaltyOfferRuleId == this.state.spendAmountForPointOfferId) {
			if (this.state.numberOfPoints == null || this.state.numberOfPoints <= 0) {
				this.setState({ errorNumberOfPoints: "You must have a number of points greater than zero" });
				validated = false;
			} else {
				this.setState({ errorNumberOfPoints: "" });
			}

			if (this.state.percentageOff == null || this.state.percentageOff <= 0) {
				this.setState(
					{ errorPercentageOff: "You must have a percentage discount greater than zero" }
				);
				validated = false;
			} else {
				this.setState({ errorPercentageOff: "" });
			}
		}

		return validated;
    }

	render() {
		let loyaltyOfferRulesDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayLoyaltyOfferRulesDropdown();
		let businessDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayBusinessDropdown();
		let loyaltyOfferDetails;

		if (this.state.loyaltyOfferRuleId == this.state.spendAmountForPointOfferId)
			loyaltyOfferDetails = <><label>
                SpendRequirement:
                <input type="number" name="spendRequirement" value={this.state.spendRequirement} onChange={this.handleInputChange} />
			</label>{this.state.errorSpendRequirement && <p className="error">{this.state.errorSpendRequirement}</p>}<label>
                    NumberOfPoints:
                    <input type="number" name="numberOfPoints" value={this.state.numberOfPoints} onChange={this.handleInputChange} />
				</label>{this.state.errorNumberOfPoints &&
					//display an error here
					<p className="error">{this.state.errorNumberOfPoints}</p>
				}<label>
                    PercentageOff:
                    <input type="number" name="percentageOff" value={this.state.percentageOff} onChange={this.handleInputChange} />
				</label>{this.state.errorPercentageOff && <p className="error">{this.state.errorPercentageOff}</p>}
			</>

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					LoyaltyOfferRules
					{loyaltyOfferRulesDropdown}{this.state.errorLoyaltyOfferId && <p className="error">{this.state.errorLoyaltyOfferId}</p>}
				</label>

				<label>
					Business
					{businessDropdown}
				</label>

				<label>
					SignUpAlertText:
					<input type="text" name="signUpAlertText" value={this.state.signUpAlertText} onChange={this.handleInputChange} />
				</label>{this.state.signUpAlertText && <p className="error">{this.state.signUpAlertText}</p>}

				{loyaltyOfferDetails}


				<input type="submit" value="Save" />
			</form>
		);
	}
}
