import React, { Component } from 'react'
import { Dropdown } from './Library'

export class ConsumerDeviceOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class BusinessOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}



export class Voucher extends Component {
	constructor(props) {
		super(props)
		this.state = {
			businessId: '',
			consumerDeviceId: '',
			businessVoucherNumber: '',
			title: '',
			text: '',
			pdfFile: '',
			validFrom: null,
			expiry: null,
			redeemed: null,
			consumerDevices : [],
			businesss : [],
			loading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getConsumerDeviceData();
		this.getBusinessData();

		this.setDateControlToCurrentDateTime('validFrom');
		this.setDateControlToCurrentDateTime('expiry');
	}

	setDateControlToCurrentDateTime(fieldIdentifier) {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
		var localISOTimeWithoutSeconds = localISOTime.slice(0, 16);

		var datePicker = document.querySelector('input[name=' + fieldIdentifier + ']');
		datePicker.value = localISOTimeWithoutSeconds;
		this.setState({
			[fieldIdentifier]: localISOTimeWithoutSeconds
        })
    }


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleBusinessDropdownChange = (e) => {
		this.setState({
			"businessId": e.target.value
		})
	}

	handleConsumerDeviceDropdownChange = (e) => {
		this.setState({
			"consumerDeviceId": e.target.value
        })
    }

	handleSubmit = (event) => {

		var voucher = {
			businessId: this.state.businessId,
			consumerDeviceId: this.state.consumerDeviceId,
			businessVoucherNumber: this.state.businessVoucherNumber,
			title: this.state.title,
			text: this.state.text,
			pdfFile: this.state.pdfFile,
			validFrom: this.state.validFrom,
			expiry: this.state.expiry
		}
		fetch(process.env.REACT_APP_API_ENDPOINT + 'voucher', {
			method: 'POST',
			body: JSON.stringify(voucher),
			headers: {
				'Content-Type': 'application/json'
			}
			}).then(function(response) {
			console.log(response)
			return response.json();
		});
		event.preventDefault();
	}

	async getConsumerDeviceData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'consumerDevice');
		const data = await response.json();
		this.setState({ consumerDevices: data.map((consumerDevice) => new ConsumerDeviceOption(consumerDevice.consumerDeviceId, consumerDevice.consumerDeviceId, consumerDevice.consumerDeviceId)), loading: false });
}

	DisplayConsumerDeviceDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.consumerDevices}
			onChange={this.handleConsumerDeviceDropdownChange}
			value={value}
		/>
		);
	}

	async getBusinessData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business');
		const data = await response.json();
		this.setState({ businesss: data.map((business) => new BusinessOption(business.businessId, business.businessId, business.businessName)), loading: false });
}

	DisplayBusinessDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.businesss}
			onChange={this.handleBusinessDropdownChange}
			value={value}
		/>
		);
	}



	render() {
		let consumerDeviceDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayConsumerDeviceDropdown();
		let businessDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayBusinessDropdown();

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					Business
					{businessDropdown}
				</label>

				<label>
					Device
					{consumerDeviceDropdown}
				</label>

				<label>
					BusinessVoucherNumber:
					<input type="text" name="businessVoucherNumber" value={this.state.businessVoucherNumber} onChange={this.handleInputChange} />
				</label>

				<label>
					Title:
					<input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} />
				</label>

				<label>
					Text:
					<input type="text" name="text" value={this.state.text} onChange={this.handleInputChange} />
				</label>

				<label>
					PdfFile:
					<input type="text" name="pdfFile" value={this.state.pdfFile} onChange={this.handleInputChange} />
				</label>

				<label>
					ValidFrom:
					<input type="datetime-local" name="validFrom" value={this.state.validFrom} onChange={this.handleInputChange} />
				</label>

				<label>
					Expiry:
					<input type="datetime-local" name="expiry" value={this.state.expiry} onChange={this.handleInputChange} />
				</label>


				<input type="submit" value="Save" />
			</form>
		);
	}
}
