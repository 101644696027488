import React, { Component } from 'react'
import { Dropdown } from './Library'

export class CountryOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = key;
	}
}

export class CountryCode extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: '',
			countryId: null,
			countrys : [],
			loading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getCountryData();

	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e) => {
		this.setState({
			"countryId": e.target.value
		})
	}

	handleSubmit = (event) => {

		var countryCode = {
			value: this.state.value,
			countryId: this.state.countryId
		}
		fetch(process.env.REACT_APP_API_ENDPOINT + 'countryCode', {
			method: 'POST',
			body: JSON.stringify(countryCode),
			headers: {
				'Content-Type': 'application/json'
			}
			}).then(function(response) {
			console.log(response)
			return response.json();
		});
		event.preventDefault();
	}

	async getCountryData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'country');
		const data = await response.json();
		this.setState({ countrys: data.map((country) => new CountryOption(country.countryId, country.countryId, country.countryName)), loading: false });
}

	DisplayCountryDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.countrys}
			onChange={this.handleDropDownChange}
			value={value}
		/>
		);
	}



	render() {
		let countryDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayCountryDropdown();

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					Value:
					<input type="text" name="value" value={this.state.value} onChange={this.handleInputChange} />
				</label>

				<label>
					Country
					{countryDropdown}
				</label>


				<input type="submit" value="Save" />
			</form>
		);
	}
}
