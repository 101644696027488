import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Dropdown, postAttachment } from './Library'



export class BusinessOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class Message extends Component {

	

	writeHeaderToSession(headers, key) {
		sessionStorage.setItem(key, JSON.stringify(headers.get(key)));
	}

	getSessionValue(key) {
		const tokenString = sessionStorage.getItem(key);
		return JSON.parse(tokenString);
	}

	constructor(props) {
		super(props)
		this.state = {
			businessId: '',
			title: '',
			description: '',
			attachmentPath: '',
			timeToSend: null,
			messageLink: '',
			attachament: null,
			businesss: [],
			loading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	async componentDidMount() {
		this.getBusinessData();
		this.setDateControlToCurrentDateTime('timeToSend');
	}

	setDateControlToCurrentDateTime(fieldIdentifier) {
		var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
		var localISOTimeWithoutSeconds = localISOTime.slice(0, 16);

		var datePicker = document.querySelector('input[name=' + fieldIdentifier + ']');
		datePicker.value = localISOTimeWithoutSeconds;
		this.setState({
			[fieldIdentifier]: localISOTimeWithoutSeconds
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e) => {
		this.setState({
			"businessId": e.target.value
		})
	}

	handleFileChange = (event) => {
		this.setState({
			attachament: event.target.files[0]
		})
	}


	handleSubmit = (event) => {

		const messageData = {
			title: this.state.title,
			description: this.state.description,
			businessid: this.state.businessId,
			messageLink: this.state.messageLink
		};

		if (this.state.attachament != null) {
			console.log("posting attachment");
			postAttachment(this.state.attachament);
			messageData.attachmentPath = 'https://' + process.env.REACT_APP_MESSAGE_BUCKET_NAME + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + this.state.attachmentPath;
		}

		if (this.state.timeToSend != null) {
			messageData.timeToSend = this.state.timeToSend;
		}


		fetch(process.env.REACT_APP_API_ENDPOINT + 'message', {
			method: 'POST',
			body: JSON.stringify(messageData),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(function (response) {
			console.log(response)
			return response.json();
		});
		event.preventDefault();
	}

	async getBusinessData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business');
		const data = await response.json();
		this.setState({ businesss: data.map((business) => new BusinessOption(business.businessId, business.businessId, business.businessName)), loading: false });
	}

	DisplayBusinessDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.businesss}
			onChange={this.handleDropDownChange}
			value={value}
		/>
		);
	}

	/*postAttachment() {
		var AWS = require('aws-sdk');
		AWS.config.update({
			region: process.env.REACT_APP_AWS_REGION,
			accessKeyId: this.getSessionValue(accessKeyHeader),
			secretAccessKey: this.getSessionValue(secretKeyHeader),
			sessionToken: this.getSessionValue(sessionTokenKey)
		});

		var s3 = new AWS.S3({ apiVersion: '2006-03-01' });

		var fileType = this.state.attachament.type.split("/")[1];


		this.state.attachmentPath = uuidv4() + "." + fileType;
		var uploadParams = { Bucket: process.env.REACT_APP_MESSAGE_BUCKET_NAME, Key: this.state.attachmentPath, Body: this.state.attachament };

		s3.upload(uploadParams, function (err, data) {
			if (err) {
				console.log("Error", err);
			} if (data) {
				console.log("Upload Success", data.Location);
			}
		});
    }*/

	render() {

		let businessDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayBusinessDropdown();

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					Business
					{businessDropdown }
				</label>
				<label>
					Title:
					<input type="text" name="title" value={this.state.title} onChange={this.handleInputChange} />
				</label>

				<label>
					Description:
					<input type="text" name="description" value={this.state.description} onChange={this.handleInputChange} />
				</label>

				<label>
					Attachment:
					<input type="file" name="attachment" onChange={this.handleFileChange} />
					<div>
						{this.state.attachament != null ? (
							<><p>Filename: {this.state.attachament.name}</p><p>Filetype: {this.state.attachament.type}</p><p>Size in bytes: {this.state.attachament.size}</p>
								<p>
									lastModifiedDate:{' '}
									{this.state.attachament.lastModifiedDate.toLocaleDateString()}
								</p></>
						) : (<p>Please select a file</p>)}
					</div>
				</label>

				<label>
					TimeToSend:
					<input type="datetime-local" name="timeToSend" value={this.state.timeToSend} onChange={this.handleInputChange} />
				</label>

				<label>
					MessageLink:
					<input type="text" name="messageLink" value={this.state.messageLink} onChange={this.handleInputChange} />
				</label>

				<input type="submit" value="Send Message" />
				
			</form>
		);
	}
}
