import React, { Component } from 'react'
import { Dropdown } from './Library'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'

export class BusinessOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}

export class TillOption {
	constructor(key, value, text) {
		this.label = text;
		this.key = key;
		this.value = value;
	}
}


export class Till extends Component {
	constructor(props) {
		super(props)
		this.state = {
			businessId: '',
			tillBusinessId: '',
			businesss: [],
			tills: [],
			loading: true
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		this.getBusinessData();
		this.getTillData();
	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleDropDownChange = (e) => {
		this.setState({
			"businessId": e.target.value
		})
	}


	handleTillDropDownChange = (e) => {
		var tillLabelNode = document.getElementById("tillLabel");
		var qrTillNode = document.getElementById("qrTillCode");

		var qrTillValue = process.env.REACT_APP_API_ENDPOINT + "receipt/till/" + e.target.value;
		ReactDOM.render(<label>Till</label>, tillLabelNode);

		ReactDOM.render(
			<QRCode value={qrTillValue} />,
			qrTillNode
		);
	}

	handleSubmit = (event) => {

		var till = {
			businessId: this.state.businessId,
			tillBusinessId: this.state.tillBusinessId
		}
		fetch(process.env.REACT_APP_API_ENDPOINT + 'till', {
			method: 'POST',
			body: JSON.stringify(till),
			headers: {
				'Content-Type': 'application/json'
			}
			}).then(function(response) {
			console.log(response)
			return response.json();
		});
		event.preventDefault();
	}

	async getBusinessData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'business');
		const data = await response.json();
		this.setState({ businesss: data.map((business) => new BusinessOption(business.businessId, business.businessId, business.businessName)), loading: false });
}

	DisplayBusinessDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.businesss}
			onChange={this.handleDropDownChange}
			value={value}
		/>
		);
	}

	async getTillData() {
		const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'till');
		const data = await response.json();
		this.setState({ tills: data.map((till) => new TillOption(till.tillId, till.tillId, till.name + " - " + till.model)), loading: false });
	}

	DisplayTillDropdown() {
		const { value } = this.state;
		return (<Dropdown
			options={this.state.tills}
			onChange={this.handleTillDropDownChange}
			value={value}
		/>
		);
	}

	render() {
		let tillDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayTillDropdown();
		let businessDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayBusinessDropdown();
		

		return (
			<form onSubmit={this.handleSubmit}>
				<label>
					Business
					{businessDropdown}
				</label>

				<label>
					TillBusinessId:
					<input type="text" name="tillBusinessId" value={this.state.tillBusinessId} onChange={this.handleInputChange} />
				</label>


				<input type="submit" value="Save" />
			<h1>Till QR Code</h1>
			<label>
					Till
					{tillDropdown}
				</label>
				<table><tr><td><div id="tillLabel" /></td><td><div id="qrTillCode" /></td></tr></table>
			</form>
		);
	}
}
