import { v4 as uuidv4 } from 'uuid'

const sessionTokenKey = 'X-Amz-Session-Token';
const accessKeyHeader = 'X-Amz-Access-Key';
const secretKeyHeader = 'X-Amx-Secret-Key';
const accessTokenExpiryHeader = 'X-Amz-Session-Expiry';
export const businessIdHeader = 'businessid';
const securityToken = 'X-Amz-Security-Token';
const httpOKsuccess = '200';
const amzDate = 'X-Amz-Date';
const hostHeader = 'Host';

export const Dropdown = ({ label, value, options, onChange }) => {
	return (
		<label>
			{label}
			<select value={value} onChange={onChange}>
				<option>This is unselected</option>
				{options.map((option) => (
					<option value={option.value}>{option.label}</option>
				))}
			</select>
		</label>
	);
};

export async function isLoggedIn() {
	if (isDevelopmentEnvironment()) {
		sessionStorage.setItem(businessIdHeader, process.env.REACT_APP_TEST_BUSINESS_ID);
		sessionStorage.setItem(accessKeyHeader, process.env.REACT_APP_ACCESS_KEY);
		sessionStorage.setItem(secretAccessKey, process.env.REACT_APP_SECRET_ACCESS_KEY);
		var now = new Date();
		now.setTime(now.getTime() + days * 10);
		var isoTokenExpiry = now.toISOString().replace("-", "");
		console.log(isoTokenExpiry);
		sessionStorage.setItem(accessTokenExpiryHeader, isoTokenExpiry);
	} else {
		if (!(sessionStorage.getItem(sessionTokenKey) || await login())) {
			var currentPage = window.location.protocol + '//' + window.location.host + window.location.pathname;
			window.location.href = process.env.REACT_APP_CLOVER_BASE_URL + '/oauth/authorize?client_id=' + process.env.REACT_APP_CLOVER_APP_ID + "&redirect_uri=" + encodeURIComponent(currentPage);
		}
	}
}

function isDevelopmentEnvironment() {
	return !process.env.NODE_ENV || process.env.REACT_APP_API_ENDPOINT.includes('localhost') || process.env.REACT_APP_API_ENDPOINT.includes('ngrok');
}

export async function login() {
	var succesful;

	var merchantId = new URLSearchParams(window.location.search).get('merchant_id');
	var employeeId = new URLSearchParams(window.location.search).get('employee_id');
	var clientId = new URLSearchParams(window.location.search).get('client_id');
	var code = new URLSearchParams(window.location.search).get('code');

	
	if (merchantId && employeeId && clientId && code) {

		const loginResponse = await fetch(process.env.REACT_APP_API_ENDPOINT + 'clover/Login?merchant_id=' + merchantId + '&employee_id=' + employeeId + '&client_id=' + clientId + '&code=' + code);

		succesful = (loginResponse.status == httpOKsuccess);

		if (succesful) {
			const headers = await loginResponse.headers;
			writeHeaderToSession(headers, sessionTokenKey);
			writeHeaderToSession(headers, accessKeyHeader);
			writeHeaderToSession(headers, secretKeyHeader)
			writeHeaderToSession(headers, businessIdHeader);
		}

		return succesful;
	}
	return false;
}

function writeHeaderToSession(headers, key) {
	sessionStorage.setItem(key, JSON.stringify(headers.get(key)));
}

export function getSessionValue(key) {
	const tokenString = sessionStorage.getItem(key);
	return tokenString;
}

export function isAWSSessionValid() {
	if (isDevelopmentEnvironment()) {
		sessionStorage.setItem(businessIdHeader, process.env.REACT_APP_TEST_BUSINESS_ID);
		sessionStorage.setItem(accessKeyHeader, process.env.REACT_APP_ACCESS_KEY);
		sessionStorage.setItem(secretKeyHeader, process.env.REACT_APP_SECRET_ACCESS_KEY);
		var now = new Date();
		var numberOfSecondsInADay = 86400000;
		now.setTime(now.getTime() + numberOfSecondsInADay);
		console.log(now.toISOString());
		var isoTokenExpiry = now.toISOString().replaceAll("-", "");
		console.log(isoTokenExpiry);
		sessionStorage.setItem(accessTokenExpiryHeader, isoTokenExpiry);
		return true;
	} else {
		var tokenExpiryDate = new Date(getSessionValue(accessTokenExpiryHeader));
		var now = new Date();

		return (tokenExpiryDate > now);
	}
}

export function postAttachment(attachment) {

	if (isAWSSessionValid()) {
		var AWS = require('aws-sdk');
		AWS.config.update({
			region: process.env.REACT_APP_AWS_REGION,
			accessKeyId: getSessionValue(accessKeyHeader),
			secretAccessKey: getSessionValue(secretKeyHeader),
			sessionToken: getSessionValue(sessionTokenKey)
		});

		var s3 = new AWS.S3({ apiVersion: '2006-03-01' });

		var fileType = attachment.type.split("/")[1];


		var uploadedFileName = uuidv4() + "." + fileType;
		var uploadParams = { Bucket: process.env.REACT_APP_MESSAGE_BUCKET_NAME, Key: uploadedFileName, Body: attachment };

		s3.upload(uploadParams, function (err, data) {
		});

		return uploadedFileName;
	} else {
		isLoggedIn();
	}
}

export function postSignedMessage(messageData, endpoint) {

	const baseUrl = process.env.REACT_APP_API_ENDPOINT;
	const host = process.env.REACT_APP_API_HOST;
	const region = process.env.REACT_APP_AWS_REGION;
	const service = 'execute-api';
	const method = 'POST';

	if (isDevelopmentEnvironment()) {
		return fetch(baseUrl + endpoint, {
			method: method,
			body: messageData,
			headers: {
				'Content-Type': 'application/json'
			}
		});
	} else {

		var today = new Date();
		let awsDate = today.getUTCFullYear().toString() + getTwoDigitTime(today.getUTCMonth() + 1) + getTwoDigitTime(today.getUTCDate()) + 'T' + getTwoDigitTime(today.getUTCHours()) + getTwoDigitTime(today.getUTCMinutes()) + getTwoDigitTime(today.getUTCSeconds()) + 'Z';
		let dateStamp = today.getUTCFullYear().toString() + getTwoDigitTime(today.getUTCMonth() + 1) + getTwoDigitTime(today.getUTCDate());

		var canonicalUrl = '/Prod/api/' + endpoint;
		var canonicalQueryString = '';
		var canonicalHeaders = hostHeader.toLowerCase() + ':' + host + '\n' + amzDate.toLowerCase() + ':' + awsDate + '\n' + securityToken.toLowerCase() + ':' + getSessionValue(sessionTokenKey) + '\n';
		var signedHeaders = `${hostHeader.toLowerCase()};${amzDate.toLowerCase()};${securityToken.toLowerCase()}`;


		let canonicalRequest = 'POST\n' + canonicalUrl + '\n' + canonicalQueryString + '\n' + canonicalHeaders + '\n' + signedHeaders + '\n' + hashOfString(messageData);

		let algorithm = 'AWS4-HMAC-SHA256';
		let credentialScope = dateStamp + '/' + region + '/' + service + '/' + 'aws4_request';
		let stringToSign = algorithm + '\n' + awsDate + '\n' + credentialScope + '\n' + hashOfString(canonicalRequest);

		let signingKey = getSignatureKey(getSessionValue(secretKeyHeader), dateStamp, region, service)
		var CryptoJS = require("crypto-js");
		let signature = CryptoJS.HmacSHA256(stringToSign, signingKey).toString(CryptoJS.enc.Hex);

		let authorizationHeader = algorithm + ' ' + 'Credential=' + getSessionValue(accessKeyHeader) + '/' + credentialScope + ', ' + 'SignedHeaders=' + signedHeaders + ', ' + 'Signature=' + signature

		return fetch(baseUrl + endpoint, {
			method: method,
			body: messageData,
			headers: {
				'Authorization': authorizationHeader,
				'X-Amz-Date': awsDate,
				'X-Amz-Security-Token': getSessionValue(sessionTokenKey),
				'Content-Type': 'application/json'
			}
		});
	}
}

function hashOfString(valueToHash) {
	var CryptoJS = require("crypto-js");
	return CryptoJS.SHA256(valueToHash).toString(CryptoJS.enc.Hex);
}

function getSignatureKey(key, dateStamp, regionName, serviceName) {

	var crypto = require("crypto-js");

	var kDate = crypto.HmacSHA256(dateStamp, "AWS4" + key);
	var kRegion = crypto.HmacSHA256(regionName, kDate);
	var kService = crypto.HmacSHA256(serviceName, kRegion);
	var kSigning = crypto.HmacSHA256("aws4_request", kService);
	return kSigning;
}

function getTwoDigitTime(number) {
	return number.toString().padStart(2, '0');
}

